export const checkIsUngheni = (): boolean => {
  return process.env.REACT_APP_BRANCH_NAME === "ungheni";
};

export const changeUngheniMeta = (): void => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (!favicon) return;
  favicon.setAttribute("href", "/favicon-ungheni.ico");
  document.title = "E-gradinita Ungheni";
};
